import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"

const ProjectListing = () => {
  const data = useStaticQuery(graphql`
    query {
      projects: allSanityProjects {
        edges {
          node {
            title
            listingImage {
              asset {
                url
              }
            }
            slug {
              current
            }
          }
        }
      }
    }
  `)

  const { projects } = data
  const allProjects = projects.edges

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "bg-power-lines",
        }}
      />
      <SEO title="Our Projects" />
      <div className="container mx-auto py-24">
        <h1 className="mx-4 md:mx-0 uppercase font-bold tracking-wide mb-8 text-5xl border-b border-gray-800">
          Our Projects
        </h1>
        <div className="px-4 md:px-0 flex flex-wrap md:-mx-4">
          {allProjects.map((p, idx) => {
            let project = p.node
            return (
              <div className="w-full md:w-1/3 " key={idx}>
                <div className="bg-red-500 m-4">
                  <Link
                    to={`/projects/${project.slug.current}`}
                    className="block duotone-thumb relative"
                  >
                    <span className="block bottom-0 absolute p-8 font-black text-4xl md:text-5xl leading-tight text-shadow-md">
                      {project.title}
                    </span>
                    <img
                      src={`${project.listingImage.asset.url}?w=600&h=600&fit=crop&sat=-100`}
                      alt={`${project.title} Listing`}
                      className="shadow-md "
                    />
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default ProjectListing
